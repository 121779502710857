import { mapState, mapGetters, mapMutations } from 'vuex'
import { set as setInIDB } from 'idb-keyval'
import { name as appName } from '@/../package.json'

export class Layout implements ILayout {
  cameras: Camera[] = []
  selected: string[] = []
  count = 0
  offset = 0
  limit = 6

  constructor(cameras?: Camera[], selected?: string[], count = 0, offset = 0, limit = 6) {
    this.cameras = cameras || []
    this.selected = selected || []
    this.count = count
    this.offset = offset
    this.limit = limit
  }
}

export type LayoutMap = Record<Camera['id'], Layout>

const initialState = () => ({
  api: {} as any,
  layoutStore: null as LayoutStore | null,
  widgetProps: {} as WidgetProps,
  userId: null as string | null,
  mainLoading: true,
  currentStreamType: null as StreamType | null,
  layoutMap: {} as LayoutMap,
  permissionScopes: new Map(),
  MAX_NAME_LENGTH: 35,
  LAYOUT_TYPES: {
    '1x1': {
      gridClass: 'grid-1x1',
      class: 'half-size',
      style: {},
      count: 1,
    },
    '2x2': {
      gridClass: 'grid-2x2',
      class: 'half-size ',
      style: {},
      count: 4,
    },
    '3x3': {
      gridClass: 'grid-3x3',
      class: 'forty-three-size',
      style: {},
      count: 9,
    },
    '4x4': {
      gridClass: 'grid-4x4',
      class: '',
      style: {},
      count: 16,
    },
    '4x2': {
      gridClass: 'grid-4x2',
      class: '',
      style: {},
      count: 8,
    },
    '1x2': {
      gridClass: 'grid-1x2',
      class: '',
      withMainCamera: true,
      style: {},
      count: 2,
    },
    '1+2x2': {
      withMainCamera: true,
      gridClass: 'grid-1x2x2',
      class: 'with-main-camera-2x2 half-size',
      style: {},
      count: 13,
    },
    '1+3x3': {
      withMainCamera: true,
      gridClass: 'grid-1x3x3 grid-shrink',
      class: 'with-main-camera-3x3 ',
      style: {},
      count: 10,
    },
    '1+4x4': {
      withMainCamera: true,
      class: 'with-main-camera-4x4',
      gridClass: 'grid-1x4x4 grid-shrink',
      style: {},
      count: 17,
    },
  },
})

type State = ReturnType<typeof initialState>

const mutations = {
  setValue<T extends keyof State>(state: State, [key, value]: [T, State[T]]) {
    state[key] = value
  },
  clearLayoutStore(state: State, layoutId: string) {
    delete state.layoutStore?.[layoutId]
    state.layoutStore = { ...state.layoutStore }
    setInIDB(`${appName}${state.userId}-layouts`, state.layoutStore)
  },
  setLayoutStream(state: State, [layoutId, streamType]: [string, string]) {
    state.layoutStore = {
      ...(state.layoutStore || {}),
      [layoutId]: {
        streamType,
      },
    }
    setInIDB(`${appName}${state.userId}-layouts`, state.layoutStore)
  },
  setCameraStream(
    state: State,
    [layoutId, cameraId, streamId]: [string, string, string | undefined],
  ) {
    state.layoutStore = {
      ...(state.layoutStore || {}),
      [layoutId]: {
        ...(state.layoutStore?.[layoutId] || {}),
        camerasStreamType: {
          ...(state.layoutStore?.[layoutId]?.camerasStreamType || {}),
          [cameraId]: streamId,
        },
      },
    }
    setInIDB(`${appName}${state.userId}-layouts`, state.layoutStore)
  },
  setLayout(state: State, [key, value]: [string, Partial<Layout>?]) {
    state.layoutMap = {
      ...state.layoutMap,
      [key]: value
        ? {
            ...state.layoutMap[key],
            ...value,
          }
        : new Layout(),
    }
  },
  reset(state: State) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      // @ts-ignore
      state[key] = s[key]
    })
  },
}

type Mutations = typeof mutations

export const mapStateTyped = <T extends keyof State, G extends { [Key in T]: () => State[Key] }>(
  keys: T[],
): G => {
  return { ...mapState(keys) } as G
}

export const mapMutationsTyped = <
  T extends keyof Mutations,
  G extends {
    [K in T]: (I?: Parameters<Mutations[K]>[1]) => ReturnType<Mutations[K]>
  },
>(
  keys: T[],
): G => {
  return mapMutations(keys) as G
}

const getters = {
  api: (state: ReturnType<typeof initialState>) => state.api,
  layouts: (state: ReturnType<typeof initialState>) => state.layoutMap,
}

type Getters = typeof getters

export const mapGettersTyped = <
  T extends keyof Getters,
  G extends { [Key in T]: () => ReturnType<Getters[Key]> },
>(
  keys: T[],
): G => {
  return mapGetters(keys) as G
}

export default {
  state: initialState(),
  mutations,
  getters,
}

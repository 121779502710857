
import { mapStateTyped } from '@/store'
import { isEqual, isMatchWith } from 'lodash'
import Vue from 'vue'
export default Vue.extend({
  name: 'CameraCard',
  inject: ['spaParent'],
  props: {
    camera: Object as () => Camera,
    isFullscreen: Boolean,
    props: Object,
    editMode: Boolean,
    layoutStreamTypes: Object as () => LayoutStore[string],
  },
  data() {
    return {
      unmount: () => {},
      update: (props: any) => {},
      setCameraStream: (streamType: string) => {},
    }
  },
  computed: {
    ...mapStateTyped(['widgetProps']),
    widgetPropsLocal(): any {
      // @ts-ignore
      const spa = this.spaParent as SpaParent
      const player = spa.areas.find((e: any) => e.name === 'player')
      const defaultStreamType = this.layoutStreamTypes?.streamType
      const defaultStreamId = this.layoutStreamTypes?.camerasStreamType?.[this.camera.id]
      const defaultStreamInfo = {
        defaultStreamType,
        defaultStreamId
      }
      return player
        ? player.children.map((e: any) => {
            return {
              ...e,
              onMount: this.onMount,
              props: {
                camera: {
                  ...this.camera,
                  timezoneSettings: {
                    timezone: this.camera?.timezoneSettings?.timeZone,
                    isActive: this.camera?.timezoneSettings?.isActive,
                  },
                },
                // ratio: 'fit',
                fullScreenOnDblclick: true,
                playerMode: 'MultiMode',
                initMode: 'Live',
                viewMode: 'Small',
                coopPlayerFlag: true,
                editMode: this.editMode,
                autoplay: true,
                disableAutoSwitchToArchive: false,
                blockOnMatch: this.widgetProps.blockOnMatch,
                streamTypes: this.widgetProps.streamTypes,
                ...(e.props || {}),
                ...(this.props || {}),
                ...defaultStreamInfo,
                ...(defaultStreamType !== undefined
                  ? { switchToCurrentStreamByTypeOnFullScreen: undefined }
                  : {}),
                featureLockingDisabled: this.featureLockingDisabled,
                getStreamSetterByType: this.getStreamSetterByType,
              },
            }
          })
        : undefined
    },
    featureLockingDisabled(): boolean {
      // @ts-ignore
      const blockOnMatch = this.spaParent?.props?.blockOnMatch
      return blockOnMatch !== undefined
        ? !isMatchWith(this.camera, blockOnMatch, (objValue: unknown, srcValue: unknown) =>
            Array.isArray(srcValue) ? srcValue.includes(objValue) : isEqual(objValue, srcValue),
          )
        : false
    },
  },
  watch: {
    camera: {
      handler(val) {
        if (val) {
          this.unmount()
          setTimeout(() => {
            this.initPlayer()
          })
        }
      },
      deep: true,
    },
    layoutStreamTypes(val) {
      const streamType = val?.streamType
      this.update({
        ...(this.widgetPropsLocal?.[0]?.props || {}),
        ...(streamType !== undefined
          ? {
              switchToCurrentStreamByTypeOnFullScreen: undefined,
            }
          : {}),
      })
    },
    editMode() {
      this.update({
        ...(this.widgetPropsLocal?.[0]?.props || {}),
        editMode: this.editMode,
      })
    },
  },
  mounted() {
    this.initPlayer()
    this.$eventBus.$on(
      'switchStreamType',
      ([streamType, cameraId]: [string, string | undefined]) => {
        if (cameraId === undefined || cameraId === this.camera.id) {
          this.setCameraStream(streamType)
          cameraId === undefined && this.$emit('changeStream')
        }
      },
    )
  },
  destroyed() {
    this.unmount()
  },
  methods: {
    onMount(update: (newProps: any) => void) {
      this.update = update
    },
    getStreamSetterByType(callback: (streamType: string) => void) {
      this.setCameraStream = callback
    },
    initPlayer() {
      // @ts-ignore
      const spa = this.spaParent as SpaParent

      let ref = this.$refs[`player-${this.camera.id}`] as HTMLElement | HTMLElement[]
      if (Array.isArray(ref)) ref = ref[0] as HTMLElement

      ref.addEventListener('ChangeStream', (event: any) => {
        if (event?.detail?.id) {
          this.$emit('changeStream', event?.detail?.id)
        }
      })

      ref.addEventListener('ChangeStreamType', (event: any) => {
        if (event?.detail?.id) {
          this.$eventBus.$emit('switchStreamType', [event?.detail?.id])
        }
      })

      ref.addEventListener('ToggleFullscreen', (event: any) => {
        this.$emit('toggleFullscreen', event.detail)
      })

      ref.addEventListener('ChangeLayoutStreamType', (event: any) => {
        if (event?.detail?.streamType) {
          this.$emit('changeLayoutStreamType', event.detail.streamType)
        }
      })

      this.widgetPropsLocal && (this.unmount = spa.mountChildren(ref, this.widgetPropsLocal))
    },
  },
})
